import React, { useEffect, useState } from "react";

//Package
import { styled } from "styled-components";
import { mccConfig } from "../../axiosConfig";
import ReactPaginate from "react-paginate";

//Image
import bgimage from "../assets/images/service-top-bg.png";

function Careers({ pagination, data, setPagination, setData }) {
    // Scroll to top function
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Api Connection

    const [currentPage, setCurrentPage] = useState(1);
    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // This provides a smooth scrolling animation
        });
    }
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        const nextPageUrl = selectedPage > currentPage - 1 ? pagination.next : pagination.previous;

        if (nextPageUrl) {
            mccConfig
                .get(nextPageUrl)
                .then((response) => {
                    setData(response.data.results.careers);
                    setPagination(response.data);
                })
                .catch((err) => {});
        }
        scrollToTop();
    };

    return (
        <>
            <MainContainer>
                <TopContainer>
                    <MainHeading>Careers</MainHeading>
                    <SubHeading>HOME / CAREERS</SubHeading>
                </TopContainer>
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <BottomContainer>
                            {Array.isArray(data) &&
                                data.map((item) => (
                                    <CareerCards>
                                        <CompanyLogo>
                                            <LogoImg
                                                src={
                                                    require("../assets/images/main-logo.svg")
                                                        .default
                                                }
                                            />
                                        </CompanyLogo>
                                        <Title>{item.title}</Title>
                                        <SubTitle>{item.experience}</SubTitle>
                                        <Para>{item.description}</Para>
                                        <Hr />
                                        <ApplyButton>
                                            <a href="mailto:reemali@steyp.com">Apply Now</a>
                                        </ApplyButton>
                                    </CareerCards>
                                ))}
                        </BottomContainer>
                    </SubContainer>
                    <PaginationContainer>
                        <PaginationText></PaginationText>
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            pageCount={pagination.total_pages}
                            onPageChange={handlePageClick}
                            forcePage={currentPage - 1}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </PaginationContainer>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default Careers;

const MainContainer = styled.div``;
const WrapperContainer = styled.div``;
const SubContainer = styled.div``;
const TopContainer = styled.div`
    padding: 165px 150px 92px 150px;
    background-color: #e9f9ff;
    background-image: url(${bgimage});
    background-repeat: no-repeat;
    background-size: 19%;
    background-position: right;
    @media all and (max-width: 640px) {
        text-align: center;
    }
    @media all and (max-width: 480px) {
        @media all and (max-width: 480px) {
            padding: 140px 90px 79px;
        }
    }
`;
const BottomContainer = styled.div`
    padding: 70px 0px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
const MainHeading = styled.h2`
    color: #215366;
    font-family: "poppins_medium";
    font-size: 40px;
    margin-bottom: 25px;
`;
const SubHeading = styled.h4``;
const CareerCards = styled.div`
    border: 1px solid #a0a0a0;
    padding: 30px;
    margin-bottom: 30px;
    width: 32%;
    &:hover {
        border: 1px solid #00aae8;
    }
    @media all and (max-width: 1200px) {
        padding: 20px;
        width: 48%;
    }
    @media all and (max-width: 1080px) {
        width: 48%;
        margin-bottom: 26px;
    }
    @media all and (max-width: 768px) {
        width: 70%;
        margin: 15px auto;
    }
    @media all and (max-width: 640px) {
        width: 80%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const CompanyLogo = styled.div`
    width: 60px;
    margin-bottom: 25px;
    &.stikon {
        margin-bottom: 38px;
    }
`;
const LogoImg = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`;
const Title = styled.h3`
    font-size: 21px;
    color: #222;
    font-family: "poppins_medium";
    margin-bottom: 5px;
    @media all and (max-width: 360px) {
        font-size: 19px;
    }
`;
const SubTitle = styled.h5`
    margin-bottom: 20px;
`;
const Para = styled.p`
    color: #a0a0a0;
    margin-bottom: 25px;
    @media all and (max-width: 1200px) {
        font-size: 15px;
    }
`;
const Hr = styled.hr`
    border: 1px solid #a0a0a0;
    margin-bottom: 30px;
`;
const ApplyButton = styled.button`
    color: #ffffff;
    background-color: #00acea;
    text-align: center;
    height: 42px;
    cursor: pointer;
    width: 100%;
    a {
        color: #ffffff;
    }
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    margin-left: 20px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
