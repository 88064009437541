import React from "react";

//Packages
import { styled } from "styled-components";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

function Footer() {
    return (
        <>
            <MainContainer>
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <LeftBox>
                            <LogoBox>
                                <LogoImg src={require("../assets/images/main-logo.svg").default} />
                            </LogoBox>
                            <Para>
                                Malabar Constructions & Contractors is always committed to building
                                innovative, high-quality, functional spaces that exceed our clients'
                                expectations.
                            </Para>
                            <SocialIcons>
                                <IconBox>
                                    <IconImg
                                        src={require("../assets/images/fb-logo.svg").default}
                                    />
                                </IconBox>
                                <IconBox>
                                    <IconImg
                                        src={require("../assets/images/insta-logo.svg").default}
                                    />
                                </IconBox>
                                <IconBox>
                                    <IconImg
                                        src={require("../assets/images/twitter-logo.svg").default}
                                    />
                                </IconBox>
                                <IconBox>
                                    <IconImg
                                        src={require("../assets/images/linkedIn-logo.svg").default}
                                    />
                                </IconBox>
                            </SocialIcons>
                        </LeftBox>
                        <RightBox>
                            <MiddleBox>
                                <Heading>Company</Heading>
                                <SubListNew
                                    to="about"
                                    spy={true}
                                    duration={500}
                                    smooth={true}
                                    offset={-60}
                                >
                                    About
                                </SubListNew>
                                <SubListNew
                                    to="service"
                                    spy={true}
                                    duration={500}
                                    smooth={true}
                                    offset={-60}
                                >
                                    Service
                                </SubListNew>
                                <SubList to="/projects">Projects</SubList>
                                <SubList to="/careers">Careers</SubList>
                                <SubListNew
                                    to="contact"
                                    spy={true}
                                    duration={500}
                                    smooth={true}
                                    offset={-60}
                                >
                                    Contact
                                </SubListNew>
                            </MiddleBox>
                            <RightContainer>
                                <Heading>Contact</Heading>
                                <Infobox>
                                    <ImageContainer>
                                        <InfoIcon
                                            src={require("../assets/images/call-logo.svg").default}
                                        />
                                    </ImageContainer>
                                    <NumberBox>
                                        <InfoHeading>+91 9846949989</InfoHeading>
                                    </NumberBox>
                                </Infobox>
                                <Infobox>
                                    <ImageContainer>
                                        <InfoIcon
                                            src={require("../assets/images/icon.svg").default}
                                        />
                                    </ImageContainer>
                                    <InfoHeading>
                                        2nd Floor, Logtech Tower, Bypass Junction, Kazhakkoottam,
                                        Trivandrum, Kerala, India - 695582
                                    </InfoHeading>
                                </Infobox>
                            </RightContainer>
                        </RightBox>
                    </SubContainer>
                </WrapperContainer>
                <BottomContainer>
                    <TitleRow>&copy;2023 Malabar Constructions & Contractors </TitleRow>
                </BottomContainer>
            </MainContainer>
        </>
    );
}

export default Footer;

const MainContainer = styled.div`
    padding-top: 70px;
    background-color: #e9f9ff;
    @media all and (max-width: 640px) {
        padding-top: 50px;
    }
    @media all and (max-width: 400px) {
        padding-top: 0px;
    }
`;
const WrapperContainer = styled.div``;
const SubContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media all and (max-width: 768px) {
        flex-wrap: wrap;
    }
`;
const LeftBox = styled.div`
    width: 40%;
    @media all and (max-width: 1200px) {
        width: 47%;
    }
    @media all and (max-width: 768px) {
        width: 100%;
    }
`;
const LogoBox = styled.div`
    width: 70px;
    margin-bottom: 27px;
    @media all and (max-width: 768px) {
        margin: 10px auto;
    }
`;
const LogoImg = styled.img``;
const Para = styled.p`
    color: #215366;
    width: 80%;
    margin-bottom: 25px;
    @media all and (max-width: 1200px) {
        font-size: 15px;
    }
    @media all and (max-width: 768px) {
        width: 100%;
        text-align: center;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
const SocialIcons = styled.ul`
    display: flex;
    @media all and (max-width: 768px) {
        justify-content: center;
        margin-bottom: 40px;
    }
`;
const IconBox = styled.li`
    width: 28px;
    cursor: pointer;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
`;
const IconImg = styled.img`
    cursor: pointer;
`;
const MiddleBox = styled.div`
    width: 30%;
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const RightContainer = styled.div`
    width: 50%;
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Heading = styled.h3`
    font-family: "poppins_medium";
    margin-bottom: 20px;
`;

const RightBox = styled.div`
    width: 50%;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 980px) {
        width: 58%;
    }
    @media all and (max-width: 768px) {
        margin: 0 auto;
        width: 100%;
    }
    @media all and (max-width: 480px) {
        flex-wrap: wrap;
    }
`;
const Infobox = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
`;
const NumberBox = styled.div``;
const ImageContainer = styled.div`
    width: 24px;
    margin-right: 20px;
`;
const InfoIcon = styled.img`
    width: 20px;
`;
const InfoHeading = styled.p`
    color: #215366;
    @media all and (max-width: 1200px) {
        font-size: 15px;
    }
`;

const BottomContainer = styled.div`
    border-top: 1px solid #d3d3d3;
`;
const TitleRow = styled.p`
    text-align: center;
    padding: 25px 0px;
    color: #a0a0a0;
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;

const SubList = styled(NavLink)`
    display: block;
    margin-bottom: 18px;
`;
const SubListNew = styled(Link)`
    margin-bottom: 18px;
    display: block;
    cursor: pointer;
    @media all and (max-width: 1200px) {
        font-size: 16px;
    }
`;
