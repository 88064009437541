import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

//Packages
import { styled } from "styled-components";

import bgimage from "../../assets/images/bottom-bg.png";
import { mccConfig } from "../../../axiosConfig";

function Projects() {
    //Api Connection
    const [data, setData] = useState([]);

    useEffect(() => {
        mccConfig
            .get("api/v1/projects/")
            .then((response) => {
                setData(response.data.results);
            })
            .catch((err) => {});
    }, []);

    const firstTwoProjects = data.slice(0, 2);
    return (
        <>
            <MainContainer>
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <Small>- WHAT WE DO</Small>
                        <TopContainer>
                            <Heading>
                                Our <span>Projects</span>
                            </Heading>
                            <Button to="/projects">View All</Button>
                        </TopContainer>
                        <BottomContainer>
                            {firstTwoProjects.map((item) => (
                                <ServiceCard key={item.id}>
                                    <TopBox>
                                        <MainImageBox>
                                            <MainImg src={item.image} />
                                        </MainImageBox>
                                        <NameTitle>{item.name}</NameTitle>
                                        <Hr />
                                    </TopBox>
                                    <BottomBox>
                                        <MainList>
                                            <SubList>
                                                <IconBox>
                                                    <IconImg
                                                        src={
                                                            require("../../assets/images/project-icon1.svg")
                                                                .default
                                                        }
                                                    />
                                                </IconBox>
                                                <InfoBox>
                                                    <Title>Client</Title>
                                                    <SubTitle>{item.client}</SubTitle>
                                                </InfoBox>
                                            </SubList>
                                            <SubList>
                                                <IconBox>
                                                    <IconImg
                                                        src={
                                                            require("../../assets/images/project-icon2.svg")
                                                                .default
                                                        }
                                                    />
                                                </IconBox>
                                                <InfoBox>
                                                    <Title>Duration</Title>
                                                    <SubTitle>{item.duration}</SubTitle>
                                                </InfoBox>
                                            </SubList>
                                            <SubList>
                                                <IconBox>
                                                    <IconImg
                                                        src={
                                                            require("../../assets/images/project-icon3.svg")
                                                                .default
                                                        }
                                                    />
                                                </IconBox>
                                                <InfoBox>
                                                    <Title>Size</Title>
                                                    <SubTitle>{item.size}</SubTitle>
                                                </InfoBox>
                                            </SubList>
                                            <SubList>
                                                <IconBox>
                                                    <IconImg
                                                        src={
                                                            require("../../assets/images/project-icon4.svg")
                                                                .default
                                                        }
                                                    />
                                                </IconBox>
                                                <InfoBox>
                                                    <Title>Cost</Title>
                                                    <SubTitle>{item.cost}</SubTitle>
                                                </InfoBox>
                                            </SubList>
                                        </MainList>
                                    </BottomBox>
                                </ServiceCard>
                            ))}
                        </BottomContainer>
                    </SubContainer>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default Projects;

const MainContainer = styled.div`
    padding: 100px 0px;
    background: #0e232b;
    background-image: url(${bgimage});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right;
    background-position-y: top;
`;
const WrapperContainer = styled.div``;
const SubContainer = styled.div``;
const Small = styled.h4`
    color: #ffffff;
    margin-bottom: 20px;
`;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
`;
const Heading = styled.h2`
    color: #ffffff;
    font-family: "poppins_medium";
    font-size: 37px;
    span {
        color: #00acea;
    }
    @media all and (max-width: 1200px) {
        font-size: 34px;
    }
    @media all and (max-width: 480px) {
        font-size: 31px;
    }
    @media all and (max-width: 360px) {
        font-size: 28px;
    }
`;
const Button = styled(NavLink)`
    background-color: #00acea;
    color: #ffff;
    text-align: center;
    width: 120px;
    padding: 10px 24px;
    @media all and (max-width: 480px) {
        font-size: 28px;
        width: 97px;
        padding: 8px 19px;
        font-size: 14px;
    }
`;
const BottomContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
    }
`;
const ServiceCard = styled.div`
    padding: 30px;
    width: 48%;
    border: 1px solid var(--ui-design-main-primary, #00aae8);
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 20px auto;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        padding: 20px;
    }
`;
const TopBox = styled.div``;
const MainImageBox = styled.div`
    margin-bottom: 20px;
    height: 354px;
    @media all and (max-width: 480px) {
        height: 170px;
    }
`;
const MainImg = styled.img`
    display: block;
    height: 100%;
    width: 100%;
    object-fit: fill;
`;
const NameTitle = styled.h3`
    color: #ffffff;
    font-size: 25px;
    margin-bottom: 20px;
    @media all and (max-width: 1200px) {
        font-size: 17px;
    }
`;
const Hr = styled.hr`
    border: 1px solid #a0a0a0;
    margin-bottom: 30px;
`;
const BottomBox = styled.div``;
const MainList = styled.ul`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
const SubList = styled.li`
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
    width: 45%;
    &:nth-last-child(-n + 2) {
        margin-bottom: 0;
    }
    @media all and (max-width: 1200px) {
        width: 46%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
        &:nth-last-child(-n + 2) {
            margin-bottom: 25px;
        }
    }
`;
const IconBox = styled.div`
    width: 30px;
    margin-right: 15px;
    @media all and (max-width: 480px) {
        width: 20px;
    }
`;
const IconImg = styled.img`
    display: block;
    width: 25px;
`;
const InfoBox = styled.div``;
const Title = styled.h6`
    color: #fbfbfb;
    font-family: "poppins_regular";
    margin-bottom: 5px;
`;
const SubTitle = styled.div`
    color: #ffffff;
    @media all and (max-width: 1080px) {
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
